import { notificationsGetters } from './types'
import { Notification } from 'Models/Notification'

export default {
  [notificationsGetters.IS_LOADING]: state => state.isLoading,

  [notificationsGetters.IS_NEXT_LOADING]: state => state.isNextLoading,

  [notificationsGetters.LIST]: state => state.list
    .map(item => new Notification(item)),

  [notificationsGetters.FETCH_NEXT]: state => state.fetchNext,
}
